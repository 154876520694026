import React from "react";
import "./resume.scss";
import classNames from "classnames";
import {Title} from "../../elements/Title/title";

export const Resume = props => {

    const classnames = classNames(`m-resume`, {

    });

    return (
        <div className={classnames}>
            <Title content={props.title}/>
            {props.children}
        </div>
    );
};

export default Resume;